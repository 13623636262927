import UploadUtil,{IUploadDataObj} from "@/components/base_comp/upload/uploadUtil";
import {ref, reactive, toRefs, onBeforeMount, onMounted, defineComponent, getCurrentInstance, inject, watch, nextTick, computed} from 'vue';
import {UploadFile, UploadFiles, UploadRawFile, UploadRequestOptions} from "element-plus/lib/components";
export default defineComponent({
    name: "UploadComp",
    props: {
        beforeRemove: {//删除附件之前
            type: Function, default: async (file:UploadFile, fileList:UploadFiles) => {return true;}
        },
        afterRemove: {//删除附件之后
            type: Function, default: async (file:UploadFile, fileList:UploadFiles) => {return true;}
        },
        beforeUpload: {//上传之前
            type: Function, default: async (file:UploadRawFile,img:any,inst:any) => {return true;}
        },
        buildUploadParams: {//构建上传参数
            type: Function, default: async (formData:any,params:UploadRequestOptions,inst:any) => {return {};}
        },
        afterResult: {//没有自定义处理上传结果的前提下，由"系统"处理完成之后，会调用该方法
            type: Function, default: async (res:any,options:any,proxy:any) => {return {};}
        },
        handleSuccess: {//上传成功之后
            type: Function, default: async (response:any, file:UploadFile, fileList:UploadFiles) => {return {};}
        },
    },
    setup(props,context){
        const {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        const uploadComp=ref(null);
        let dataObj:IUploadDataObj=reactive<IUploadDataObj>({
            utilInst:{} as any,
            refMap:new Map(),
            resultMsg:"",
            uploadDialogVisible:false,//点击文件，让文件在弹出框中显示，主要应用于图片，showInDialog为true的时候，该参数的配置才有意义
            dialogImageUrl:'',//在弹出框中显示的文件
            fileList:[],//上传组件默认显示的文件（数组里面的对象必须包含name和url属性，其它属性可有可无，我添加了一个id属性）
            uploadParams:{
                title:proxy.$t('upload.upload'),//上传按钮的显示文本
                showFileList:false,//是否显示已上传文件的列表
                disabled:false,//是否禁止上传
                listType:'text',//上传文件的展示方式【text、picture、picture-card】text：一行一行的文本；picture：一行一行的图片；picture-card：一行多个图片
                multiple:true,//上传的时候，是否支持多选文件
                drag:false,//是否启用拖拽上传
                limitNum:5,//最大允许上传文件个数
                // accept:'.jpg,.jpeg,.png,.gif,.bmp,.pdf,.JPG,.JPEG,.PBG,.GIF,.BMP,.PDF',
                accept:'',//运行上传的文件类型，''表示运行所有类型
                autoUpload:true,//选择文件之后，是否自动上传，为false，需要手动处理
                autoLoad:true,//组件初始化的时候，是否自动加载已上传文件信息

                uploadType:'commonFile',
                aliCloudPre:'',//文件在云服务器上的存储前缀（最外层文件夹名称）
                type:1,//模块的类别，后台用，比如身份证表示1，驾驶证表示2，一般由业务模块传入
                saveType:utils.Tools.getPropFromVuex('uploadSaveType'),//文件上传处理方式：0-自己处理上传 1-阿里云存储
                action:'',//上传文件处理地址
                showInDialog:true,//如果是上传的图片，是否允许图片在弹出框中显示，可以上一张、下一张的点击切换
                canDelFile:true,//是否允许删除上传的文件
                fileSize:10,//上传文件大小限制，单位是兆M
                belongMaxId:'',
                belongMinId:'',
                uploadBtnType:0,//上传按钮样式类别，[0,1]0-就是文字；1-独占一行，且按钮有阴影效果
                selfFileTemplate:false,//是否自己处理图片显示方式
                iconStyle:'cursor: pointer;font-size: 20px;color: #2980b9;width: 100%;display: flex;justify-content: center'//上传按钮默认样式
            },
            uploadResultDrawer:false,//上传结果抽屉显示/影藏控制标识
            otherParams:{
                params:{},
                uploadPercentage:100//上传文件进度，100不会显示出来，即影藏
            }
        });
        onBeforeMount(()=>{
            dataObj.utilInst=new UploadUtil(proxy,dataObj,props,context);
        })
        onMounted(async ()=>{
            Object.assign(dataObj.uploadParams, context.attrs);
            switch (dataObj.uploadParams.uploadBtnType) {
                // 修改上传按钮的样式
                case 1://独占一行，且有阴影效果，非常奇怪，如果采用classList.add('uploadBtnClass');把样式写在外部不成功
                    const uploadBtn:any = (uploadComp.value as any).$el.querySelector('.el-upload');
                    uploadBtn.style = 'width:100%;height: 100px;line-height: 100px;text-align: center;box-shadow: 3px 3px 4px 4px grey;border-radius: 5px;';
                    // uploadBtn.classList.add('uploadBtnClass');
                    break;
            }
        });
        //监听belongMaxId变化，用于根据belongMaxId加载所有已上传的文件
        watch(() => dataObj.uploadParams.belongMaxId,async (newValue,oldValue) => {
            if(newValue && dataObj.uploadParams.autoLoad)await loadFileList(dataObj.uploadParams);//如果文件归属大类不为空，则加载组件的文件集合
        })
        //加载文件列表
        const loadFileList=async (options:any)=>{
            //加载模块的文件列表，上传的时候没有指定小类-type，会默认是0，那么加载模块文件列表的时候也可以不用指定小类，这样就默认会按文件大类加载文件列表
            let res=await utils.Api.loadByBelongMaxIdAndType(options);
            dataObj.fileList=[];
            res.uploadFiles.forEach((item:any)=>{
                dataObj.fileList.push({name:item.preName,url:item.path,id:item.id,param:item.param?JSON.parse(item.param):{}});
                // if(item.param)dataObj.otherParams.params=JSON.parse(item.param);
            });
        }
        //是否显示上传按钮(返回布尔类型，用于绝对是否采用影藏样式)
        const hideUploadBtn=computed(()=>{
            return dataObj.fileList.length==dataObj.uploadParams.limitNum || dataObj.uploadParams.disabled;
        })
        //在弹出框中展示的图片集合
        const picUrlList=computed(()=>{
            return dataObj.fileList.map((item:any)=>item.url);//这里应该过滤以下才好，判断是否是图片
        })
        //上传之前
        const beforeUpload=async (rawFile: UploadRawFile)=>{
            return dataObj.utilInst.beforeUpload(rawFile);
        }
        //上传文件
        const doUpload=async (params:UploadRequestOptions)=>{
            await dataObj.utilInst.doUpload(params);
        }
        //上传成功
        const handleSuccess=(response:any, file:UploadFile, fileList:UploadFiles)=>{
            dataObj.fileList.push({name:file.name,uid:file.uid});//tmd，上传成功之后，没有自动添加，但是删除了却自动把fileList给更新了
            props.handleSuccess(response, file, fileList);
        }
        //上传失败
        const handlerError=async (error:any,file:any, fileList:UploadFiles)=>{
            await dataObj.utilInst.error(error,file,fileList);
        }
        //删除文件之前
        const beforeRemove=async(file: UploadFile)=>{
            return await dataObj.utilInst.beforeRemove(file);
        }
        //删除文件
        const handleRemove=async (file: UploadFile)=>{
            await dataObj.utilInst.remove(file);
        }
        //自定义模板删除文件
        const handleRemoveFile=async (file: UploadFile)=>{
            if(await beforeRemove(file)){
                await handleRemove(file);
            }
        }
        //点击文件列表中已上传的文件时的钩子
        const handlePreview=async (file:any)=>{
            if(context.attrs['handlePreview']){
                if(!file.id){
                    let _file=dataObj.fileList.find((item:any)=>item.uid=file.uid);
                    if(_file)file.id=_file.id;
                }
                (context.attrs['handlePreview'] as Function)(file);
            }else if(dataObj.uploadParams.showInDialog){//是否在弹出框种显示点击的文件
                dataObj.uploadDialogVisible=true;//打开弹出框
                dataObj.dialogImageUrl=file.url as string;//给弹出框中正在显示的文件地址赋值
            }
        }
        //当上传的文件超过指定的数量时候，提示超标，并且停止上传。files-表示上传失败的文件集合；fileList-表示目前el-upload组件已有的文件集合
        //如果最多上传2个文件，假如现在一个都没有，我们选择3个文件进行上传，那么一个都不会上传成功，files就是那3个文件，fileList为空
        const handleExceed=(files:File[], fileList:UploadFiles)=>{
            proxy.$message.warning(proxy.$t('upload.limitNumPre')+dataObj.uploadParams.limitNum+proxy.$t('upload.limitNumSuf'));
        }
        return{
            ...toRefs(dataObj),uploadComp,loadFileList,hideUploadBtn,picUrlList,
            beforeUpload,doUpload,handleSuccess,handlerError,beforeRemove,handleRemove,handleRemoveFile,handlePreview,handleExceed
        }
    }
})